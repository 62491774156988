<template>
  <div class="content-wrapper overflow-visible">
    <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.params.pageSlug" />

    <div class="content">
      <template v-if="isListDetail">
       <div class="page-title pt-0 pb-3">
          <a href="javascript:;" @click="$router.back()" class="text-dark">
            <i class="icon-arrow-left52 mr-1"></i>
            <span class="font-weight-semibold">Kembali</span>
          </a>
        </div>
        <div class="row g-2">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header bg-white">
                <div class="d-flex align-items-center">
                  <h5 class="card-title font-weight-semibold mr-auto">Tindakan Fisioterapi</h5>
                </div>
              </div>
              <table class="table table-bordered table-sm patient-table">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Nama & No. RM</th>
                    <th>Diagnosa</th>
                    <th style="width:35%">Tindakan</th>
                    <th>Tanggal Tindakan</th>
                    <th>PPA</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(v,k) in (dataTindakanFisioterapi||[])" :key="k">
                    <td>
                      <span>{{k+1}}</span>
                    </td>
                    <td>
                      <div class="d-flex flex-column align-items-start">
                        <a href="javascript:;" class="font-weight-semibold border-bottom">{{row.ap_fullname||"-"}}</a>
                        <small>{{row.ap_code}}</small>
                        <small class="text-secondary">Terdaftar {{rowReg.aranr_reg_date | moment("DD MMMM YYYY, HH:mm")}}</small>
                      </div>
                    </td>
                    <td>
                      {{v.arantf_diagnosa||"-"}}
                    </td>
                    <td>
                      
                      <div v-if="(v.arantf_elektroterapi||[]).length">
                        <span class="font-weight-semibold">Elektroterapi</span>
                        <ul class="mb-0 pl-3">
                          <li v-for="(v1,k1) in (v.arantf_elektroterapi||[])" :key="k1+'elektroterapi'">
                              <span v-if="v1=='LE'" class="align-middle">{{v.arancptlf_elektroterapi_text||"-"}}</span>
                              <span v-else class="align-middle">{{v1||"-"}}</span>
                          </li>
                        </ul>
                      </div>

                      <div class="mt-2" v-if="(v.arantf_mekanoterapi||[]).length">
                        <span class="font-weight-semibold">Mekanoterapi</span>
                        <ul class="mb-0 pl-3">
                          <li v-for="(v1,k1) in (v.arantf_mekanoterapi||[])" :key="k1+'mekanoterapi'">
                            <span v-if="v1=='LM'" class="align-middle">{{v.arantf_mekanoterapi_text||"-"}}</span>
                            <span v-else class="align-middle">{{v1||"-"}}</span>
                          </li>
                        </ul>
                      </div>

                      <div class="mt-2" v-if="(v.arantf_latihan||[]).length">
                        <span class="font-weight-semibold">Latihan</span>
                        <ul class="mb-0 pl-3">
                          <li v-for="(v1,k1) in (v.arantf_latihan||[])" :key="k1+'latihan'">
                            <span v-if="v1=='LL'" class="align-middle">{{v.arantf_latihan_text||"-"}}</span>
                            <span v-else class="align-middle">{{v1||"-"}}</span>
                          </li>
                        </ul>
                      </div>

                      <span v-if="!((v.arantf_elektroterapi||[]).length + (v.arantf_mekanoterapi||[]).length + (v.arantf_latihan||[]).length)">Tidak ada Tindakan</span>
                    </td>
                    <td>
                      <span v-if="v.arantf_submit_date">
                        {{v.arantf_submit_date | moment("DD MMMM YYYY, HH:mm")}}                    
                      </span>
                      <span v-else> - </span>
                    </td>
                    <td>
                      {{v.arantf_verif_by_name||"-"}}
                    </td>
                    <td>
                      <template v-if="v.arantf_is_draft == 'Y'">
                        <span class="badge bg-warning mb-1">Draft </span>
                      </template>
                      <template v-else>
                        <div v-b-tooltip.hover title="Status Fisioterapi">
                          <span v-if="v.arantf_status == 'WAITING'" class="badge bg-warning mb-1">Menunggu </span>
                          <span v-else-if="v.arantf_status == 'PROSES'" class="badge bg-info mb-1">Dalam Tindakan</span>
                          <span v-else class="badge bg-success mb-1">Selesai </span>
                        </div>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="!(dataTindakanFisioterapi||[]).length">
                    <td colspan="99" class="text-center">Tidak ada Data</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>


<script>
  import GlobalVue from '@/libs/Global.vue'
  import $ from 'jquery'
  const _ = global._
  import Gen from '@/libs/Gen.js'
  import PatientInfo from '@/components/Ranap/PatientInfo.vue'
  
  export default {
    extends: GlobalVue,
    data() {
      return {
        rowReg: {},
        dataTindakanFisioterapi: [],
        modalConfig: {
          title: '',
          size: 'sm'
        },
        isModal: '',
        rowEdit: {},
        openModal: false,

        patientData: {},
        loading: {
            patientInfo: false,
        }
      }
    },
    components:{
      PatientInfo,
    },
    computed: {
      isListDetail(){ return !this.$route.params.radiologiNo },  
      formName(){ return this.$route.query.form },    
    },
    methods: {
      
      getPatientInfo() {
        this.$set(this.loading, 'patientInfo', true)
        Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
            data: {
              type: "get-data-pasien",
              id_registrasi: this.$route.params.pageSlug
            }
        },
        "POST"
        ).then(resp => {
        this.$set(this, 'patientData', resp.data.data)

        this.$set(this.loading, 'patientInfo', false)
        })
      },

      apiGet(params = {}, page = 1) {
        if (!this.pageSlug) {
          this.$router.push({
            name: 'Dashboard'
          }).catch(() => {})
        }

        if (this.pageSlug || this.$route.name == 'Dashboard') this.loadingOverlay = true
        this.data.data = false
        let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
        let url = this.modulePage + '/' + this.$route.params.pageSlug

        Gen.apiRest(
          "/get/" + url, {
            params: Object.assign({
              page: page
            }, paramsQuery, params.query || {})
          }
        ).then(res => {
          this.loadingOverlay = false
          _.forEach(res.data, (v, k) => {
            this.$set(this, k, v)
          })
          if (!this.isFound) {
            this.$router.push({
              name: 'Dashboard'
            }).catch(() => {})
          }
        }).catch(() => {
          this.loadingOverlay = false
        })
      }
    },
    mounted() {
      this.apiGet()
      this.getPatientInfo()
    },
    watch:{
      $route(){
        this.apiGet()
      }
    }
  }
</script>